import React from "react";
import { Box, Typography } from "@mui/material";
import Image from "mui-image";
import logo from "../../Assets/logo.png";
import { grey } from "@mui/material/colors";
import bg from "../../Assets/fire.jpg";

const Home = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "100vh",
        backgroundColor: grey[900],
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        justifyItems: "center",
        textAlign: "center",
      }}
    >
      <Box>
        <Image
          src={logo}
          style={{
            height: "500px",
            width: "500px",
          }}
        />
      </Box>
      <Typography
        variant="h2"
        fontWeight="500"
        fontFamily="Londrina Sketch"
        color="#ffff"
      >
        UNDER CONSTRUCTION
      </Typography>
      <Typography variant="h2" fontFamily="Londrina Sketch" color="#ffff">
        For BBQ CATERING <br />
        CALL: 682-262-7553
      </Typography>
    </Box>
  );
};

export default Home;
